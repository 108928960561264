.catalogue__container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    img {
        width: 100%;
        object-fit: cover;
        margin: 0;
        padding: 0;
        height: 300px;
        position: relative;
        
        @media (max-width: 600px) {
            height: 100px;
        }
    }

    .catalogue__menu{
        height: 110px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 10px;
    }

    .catalogue {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin: 0 auto;
        height: 80%;
        width: 90%;
        margin-top: 0px;

        @media (min-width: 300px) {
            height: 300px;
            width: 300px;
        }

        @media (min-width: 400px) {
            height: 400px;
            width: 400px;
        }

        @media (min-width: 500px) {
            height: 500px;
            width: 500px;
        }

        @media (min-width: 600px) {
            height: 600px;
            width: 600px;
        }
    }
}

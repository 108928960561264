.button-menu__container {
    height: 90px;
    width: 90px;
    justify-content: center;


    .button-menu__wallpaper {
        display: block;
        margin: auto;
        width: 70px;
        height: 70px;
        border-radius: 35px;
        margin: auto;
    }

    .button-menu__selected {
        border-color: $accent;
    }

    .button-menu__title {
        border-radius: 10px;
        color: $accent-variant;
        height: 25px;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
        display: block;
    }
}

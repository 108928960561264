.navbar__main-container {
    background-color: rgba($color: $primary, $alpha: 0.5);
    height: 130px;

    .navbar__brand-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: 5px;

        img {
            height: 90px;
            object-fit: contain;
            width: 100%;
            display: block;
        }

        a {
            border-radius: 10px;
            color: $accent;
            height: 25px;
            text-align: center;
            text-decoration: none;
            font-size: 20px;
            font-weight: 800;
            display: block;

            p {
                vertical-align: middle;
            }

            &:hover {
                background-color: $primary-variant;
                color: $accent-variant;
                transition: 0.8s;
            }
        }
    }

    .icon {
        height: 40px;
        width: 40px;
        display: none;
    }
}

@media (max-width: 600px) {
    .navbar__main-container {
        height: 90px;

        .navbar__brand-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
           
    
            img {
                height: 60px;
                object-fit: contain;
                width: 100%;
                display: block;
            }
    
            a {
                border-radius: 10px;
                color: $accent;
                height: 25px;
                text-align: center;
                text-decoration: none;
                font-size: 20px;
                font-weight: 800;
                display: block;
    
                p {
                    vertical-align: middle;
                }
    
                &:hover {
                    background-color: $primary-variant;
                    color: $accent-variant;
                    transition: 0.8s;
                }
            }
        }

        .navbar__link {
            border-radius: 0;
            display: block;
            text-align: left;
            font-size: 15px;
            height: 20px;
            margin-top: 0;
            padding-top: 0;
            padding-left: 45px;
            width: 100%;
            background-color: rgba($color: $primary, $alpha: 0.3);
            transition: 0.8s;
        }

        .navbar__link-hide {
            display: none;
            transition:
                visibility 0s,
                opacity 0.5s linear;
        }

        .icon {
            display: block;
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: transparent;
        }
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

main {
    width: 100%;
}

.oculto{
    display: none !important;
}